import { createStore, combineReducers } from 'redux'
import {reducer as toastrReducer} from 'react-redux-toastr'

const initialState = {
  sidebarShow: true,
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

const reducers = {
  sidebar: changeState,
  toastr: toastrReducer
}
const reducer = combineReducers(reducers)
const store = createStore(reducer)
export default store
