import React, { Component, Suspense } from 'react'
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom'
import RequireAuth from './components/RequireAuth'
import './scss/style.scss'
import { AuthProvider } from './_context/AuthProvider'
import { ADMINISTRATOR_GROUP_ID, FINANCER_GROUP_ID, HOSPITAL_GROUP_ID } from './_helpers/Constants'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <AuthProvider>
          <Suspense fallback={loading}>
            <Routes>
              <Route path="/" element={<Outlet />}>
                {/* public routes */}
                <Route exact path="/login" name="Login Page" element={<Login />} />
                <Route exact path="/register" name="Register Page" element={<Register />} />
                <Route exact path="/404" name="Page 404" element={<Page404 />} />
                <Route exact path="/unauthorized" name="Page 500" element={<Page500 />} />

                {/* private routes */}
                <Route element={<RequireAuth allowedRoles={[ADMINISTRATOR_GROUP_ID, FINANCER_GROUP_ID, HOSPITAL_GROUP_ID]} />}>
                  <Route path="*" name="Home" element={<DefaultLayout />} />
                </Route>

                {/* catch all */}
                <Route path="/" element={<Login />} />
              </Route>
            </Routes>
          </Suspense>
        </AuthProvider>
      </BrowserRouter>
    )
  }
}

export default App
