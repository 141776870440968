import axios from "axios";
import jwtDecode from "jwt-decode";
import apiService from './api.service';
import authHeader from './auth-header.service';

const API_URL = apiService()+"/api/v1/auth/";

class AuthService {
    // login(username, password) {
    //   return axios
    //     .post(API_URL, {
    //       username,
    //       password
    //     })
    //     .then(response => {
    //       if (response.data) {
    //         const token = response.data.token;
    //         localStorage.setItem('token', token);
    //       }
    //       return response.data;
    //     });
    // }
  
    logout() {
      return localStorage.removeItem("token");
        // axios
        // .post(API_URL + "logout", {token: localStorage.getItem('token')}, { headers: authHeader() })
        // .then(response => {
        //   if (response.data) {
        //     localStorage.removeItem("token");
        //   }
        // });
    }

    getCurrentUser() {
      return localStorage.getItem('token') && jwtDecode(localStorage.getItem('token'));
    }
  
    register(username, password) {
      return axios.post(API_URL + "register", {
        username,
        password
      });
    }
  
    registerAccount(data) {
      return axios.post(API_URL + "register", data, { headers: authHeader() });
    }
  
    resetPassword(data) {
      return axios.post(API_URL + "reset-password", data, { headers: authHeader() });
    }
  
    changePassword(data) {
      return axios.patch(API_URL + "change-password", data, { headers: authHeader() });
    }
  
  }
  
  export default new AuthService();