import bri from './../assets/images/icons/bri.png';
import bca from './../assets/images/icons/bca.png';
import mandiri from './../assets/images/icons/mandiri.png';
import bni from './../assets/images/icons/bni.png';
import permata from './../assets/images/icons/permata.png';

const ADMINISTRATOR_GROUP_ID = "c8686e30-94be-11ec-a6ff-60189563";
const HOSPITAL_GROUP_ID = "e5e3cb1a-94bf-11ec-a6ff-60189563";
const FINANCER_GROUP_ID = "d6e91a54-9994-11ec-9f11-6018956308da";
const DOCTOR_GROUP_ID = "d9370790-94bf-11ec-a6ff-60189563";

const PAYMENT_REQUEST_STATUS_PENDING = "100";
const PAYMENT_REQUEST_STATUS_CANCELLED = "101";
const PAYMENT_REQUEST_STATUS_REJECTED = "102";
const PAYMENT_REQUEST_STATUS_APPROVED = "103";

const PAYMENT_REQUEST_STATUSES = [ {'label':"Semua", 'value':"all"}, { label: 'Pending', value: "100" },{ label: 'Cancelled', value: "101" }, { label: 'Rejected', value: "102" }, { label: 'Approved', value: "103" } ];

const PAYMENT_REQUEST_TRANSFER_STATUS_NOT_PAYED = "300";
const PAYMENT_REQUEST_TRANSFER_STATUS_PAYED = "301";
const PAYMENT_REQUEST_TRANSFER_STATUSES = [ {'label':"Semua", 'value':"all"}, { label: 'Terbayar', value: "301" },{ label: 'Belum Terbayar', value: "300" } ];

const PAYMENT_EX_STATUSES = [ {'label':"Semua", 'value':"all"}, {'label':"PENDING", 'value':"PENDING"}, { label: 'PAID', value: "PAID" },{ label: 'SETTLED', value: "SETTLED" }, { label: 'EXPIRED', value: "EXPIRED" } ];
const PAYOUT_EX_STATUSES = [ {'label':"Semua", 'value':"all"}, {'label':"PENDING", 'value':"PENDING"}, { label: 'COMPLETED', value: "COMPLETED" },{ label: 'FAILED', value: "FAILED" } ];

const GENDERS = [ { label: 'Laki-Laki', value: 'L' },{ label: 'Perempuan', value: 'P' } ];
const PATIENT_TYPES = [ { label: 'Rawat Jalan', value: 'rawat_jalan' },{ label: 'Rawat Inap', value: 'rawat_inap' }, { label: 'IGD', value: 'igd' } ];
const PATIENT_PAYMENT_TYPES = [ { label: 'BPJS', value: 'bpjs' },{ label: 'Umum', value: 'umum' }, { label: 'Asuransi', value: 'asuransi' } ];

const PROVINCES = [
    {"id":"11","value":"ACEH","label":"ACEH"},
    {"id":"12","value":"SUMATERA UTARA","label":"SUMATERA UTARA"},
    {"id":"13","value":"SUMATERA BARAT","label":"SUMATERA BARAT"},
    {"id":"14","value":"RIAU","label":"RIAU"},
    {"id":"15","value":"JAMBI","label":"JAMBI"},
    {"id":"16","value":"SUMATERA SELATAN","label":"SUMATERA SELATAN"},
    {"id":"17","value":"BENGKULU","label":"BENGKULU"},
    {"id":"18","value":"LAMPUNG","label":"LAMPUNG"},
    {"id":"19","value":"KEPULAUAN BANGKA BELITUNG","label":"KEPULAUAN BANGKA BELITUNG"},
    {"id":"21","value":"KEPULAUAN RIAU","label":"KEPULAUAN RIAU"},
    {"id":"31","value":"DKI JAKARTA","label":"DKI JAKARTA"},
    {"id":"32","value":"JAWA BARAT","label":"JAWA BARAT"},
    {"id":"33","value":"JAWA TENGAH","label":"JAWA TENGAH"},
    {"id":"34","value":"DAERAH ISTIMEWA YOGYAKARTA","label":"DAERAH ISTIMEWA YOGYAKARTA"},
    {"id":"35","value":"JAWA TIMUR","label":"JAWA TIMUR"},
    {"id":"36","value":"BANTEN","label":"BANTEN"},
    {"id":"51","value":"BALI","label":"BALI"},
    {"id":"52","value":"NUSA TENGGARA BARAT","label":"NUSA TENGGARA BARAT"},
    {"id":"53","value":"NUSA TENGGARA TIMUR","label":"NUSA TENGGARA TIMUR"},
    {"id":"61","value":"KALIMANTAN BARAT","label":"KALIMANTAN BARAT"},
    {"id":"62","value":"KALIMANTAN TENGAH","label":"KALIMANTAN TENGAH"},
    {"id":"63","value":"KALIMANTAN SELATAN","label":"KALIMANTAN SELATAN"},
    {"id":"64","value":"KALIMANTAN TIMUR","label":"KALIMANTAN TIMUR"},
    {"id":"65","value":"KALIMANTAN UTARA","label":"KALIMANTAN UTARA"},
    {"id":"71","value":"SULAWESI UTARA","label":"SULAWESI UTARA"},
    {"id":"72","value":"SULAWESI TENGAH","label":"SULAWESI TENGAH"},
    {"id":"73","value":"SULAWESI SELATAN","label":"SULAWESI SELATAN"},
    {"id":"74","value":"SULAWESI TENGGARA","label":"SULAWESI TENGGARA"},
    {"id":"75","value":"GORONTALO","label":"GORONTALO"},
    {"id":"76","value":"SULAWESI BARAT","label":"SULAWESI BARAT"},
    {"id":"81","value":"MALUKU","label":"MALUKU"},
    {"id":"82","value":"MALUKU UTARA","label":"MALUKU UTARA"},
    {"id":"91","value":"PAPUA","label":"PAPUA"},
    {"id":"92","value":"PAPUA BARAT","label":"PAPUA BARAT"}
  ];

const CITIES = [
    {"id":"1101","province_id":"11","label":"KAB. ACEH SELATAN","value":"KAB. ACEH SELATAN"},
    {"id":"1102","province_id":"11","label":"KAB. ACEH TENGGARA","value":"KAB. ACEH TENGGARA"},
    {"id":"1103","province_id":"11","label":"KAB. ACEH TIMUR","value":"KAB. ACEH TIMUR"},
    {"id":"1104","province_id":"11","label":"KAB. ACEH TENGAH","value":"KAB. ACEH TENGAH"},
    {"id":"1105","province_id":"11","label":"KAB. ACEH BARAT","value":"KAB. ACEH BARAT"},
    {"id":"1106","province_id":"11","label":"KAB. ACEH BESAR","value":"KAB. ACEH BESAR"},
    {"id":"1107","province_id":"11","label":"KAB. PIDIE","value":"KAB. PIDIE"},
    {"id":"1108","province_id":"11","label":"KAB. ACEH UTARA","value":"KAB. ACEH UTARA"},
    {"id":"1109","province_id":"11","label":"KAB. SIMEULUE","value":"KAB. SIMEULUE"},
    {"id":"1110","province_id":"11","label":"KAB. ACEH SINGKIL","value":"KAB. ACEH SINGKIL"},
    {"id":"1111","province_id":"11","label":"KAB. BIREUEN","value":"KAB. BIREUEN"},
    {"id":"1112","province_id":"11","label":"KAB. ACEH BARAT DAYA","value":"KAB. ACEH BARAT DAYA"},
    {"id":"1113","province_id":"11","label":"KAB. GAYO LUES","value":"KAB. GAYO LUES"},
    {"id":"1114","province_id":"11","label":"KAB. ACEH JAYA","value":"KAB. ACEH JAYA"},
    {"id":"1115","province_id":"11","label":"KAB. NAGAN RAYA","value":"KAB. NAGAN RAYA"},
    {"id":"1116","province_id":"11","label":"KAB. ACEH TAMIANG","value":"KAB. ACEH TAMIANG"},
    {"id":"1117","province_id":"11","label":"KAB. BENER MERIAH","value":"KAB. BENER MERIAH"},
    {"id":"1118","province_id":"11","label":"KAB. PIDIE JAYA","value":"KAB. PIDIE JAYA"},
    {"id":"1171","province_id":"11","label":"KOTA BANDA ACEH","value":"KOTA BANDA ACEH"},
    {"id":"1172","province_id":"11","label":"KOTA SABANG","value":"KOTA SABANG"},
    {"id":"1173","province_id":"11","label":"KOTA LHOKSEUMAWE","value":"KOTA LHOKSEUMAWE"},
    {"id":"1174","province_id":"11","label":"KOTA LANGSA","value":"KOTA LANGSA"},
    {"id":"1175","province_id":"11","label":"KOTA SUBULUSSALAM","value":"KOTA SUBULUSSALAM"},
    {"id":"1201","province_id":"12","label":"KAB. TAPANULI TENGAH","value":"KAB. TAPANULI TENGAH"},
    {"id":"1202","province_id":"12","label":"KAB. TAPANULI UTARA","value":"KAB. TAPANULI UTARA"},
    {"id":"1203","province_id":"12","label":"KAB. TAPANULI SELATAN","value":"KAB. TAPANULI SELATAN"},
    {"id":"1204","province_id":"12","label":"KAB. NIAS","value":"KAB. NIAS"},
    {"id":"1205","province_id":"12","label":"KAB. LANGKAT","value":"KAB. LANGKAT"},
    {"id":"1206","province_id":"12","label":"KAB. KARO","value":"KAB. KARO"},
    {"id":"1207","province_id":"12","label":"KAB. DELI SERDANG","value":"KAB. DELI SERDANG"},
    {"id":"1208","province_id":"12","label":"KAB. SIMALUNGUN","value":"KAB. SIMALUNGUN"},
    {"id":"1209","province_id":"12","label":"KAB. ASAHAN","value":"KAB. ASAHAN"},
    {"id":"1210","province_id":"12","label":"KAB. LABUHANBATU","value":"KAB. LABUHANBATU"},
    {"id":"1211","province_id":"12","label":"KAB. DAIRI","value":"KAB. DAIRI"},
    {"id":"1212","province_id":"12","label":"KAB. TOBA SAMOSIR","value":"KAB. TOBA SAMOSIR"},
    {"id":"1213","province_id":"12","label":"KAB. MANDAILING NATAL","value":"KAB. MANDAILING NATAL"},
    {"id":"1214","province_id":"12","label":"KAB. NIAS SELATAN","value":"KAB. NIAS SELATAN"},
    {"id":"1215","province_id":"12","label":"KAB. PAKPAK BHARAT","value":"KAB. PAKPAK BHARAT"},
    {"id":"1216","province_id":"12","label":"KAB. HUMBANG HASUNDUTAN","value":"KAB. HUMBANG HASUNDUTAN"},
    {"id":"1217","province_id":"12","label":"KAB. SAMOSIR","value":"KAB. SAMOSIR"},
    {"id":"1218","province_id":"12","label":"KAB. SERDANG BEDAGAI","value":"KAB. SERDANG BEDAGAI"},
    {"id":"1219","province_id":"12","label":"KAB. BATU BARA","value":"KAB. BATU BARA"},
    {"id":"1220","province_id":"12","label":"KAB. PADANG LAWAS UTARA","value":"KAB. PADANG LAWAS UTARA"},
    {"id":"1221","province_id":"12","label":"KAB. PADANG LAWAS","value":"KAB. PADANG LAWAS"},
    {"id":"1222","province_id":"12","label":"KAB. LABUHANBATU SELATAN","value":"KAB. LABUHANBATU SELATAN"},
    {"id":"1223","province_id":"12","label":"KAB. LABUHANBATU UTARA","value":"KAB. LABUHANBATU UTARA"},
    {"id":"1224","province_id":"12","label":"KAB. NIAS UTARA","value":"KAB. NIAS UTARA"},
    {"id":"1225","province_id":"12","label":"KAB. NIAS BARAT","value":"KAB. NIAS BARAT"},
    {"id":"1271","province_id":"12","label":"KOTA MEDAN","value":"KOTA MEDAN"},
    {"id":"1272","province_id":"12","label":"KOTA PEMATANGSIANTAR","value":"KOTA PEMATANGSIANTAR"},
    {"id":"1273","province_id":"12","label":"KOTA SIBOLGA","value":"KOTA SIBOLGA"},
    {"id":"1274","province_id":"12","label":"KOTA TANJUNG BALAI","value":"KOTA TANJUNG BALAI"},
    {"id":"1275","province_id":"12","label":"KOTA BINJAI","value":"KOTA BINJAI"},
    {"id":"1276","province_id":"12","label":"KOTA TEBING TINGGI","value":"KOTA TEBING TINGGI"},
    {"id":"1277","province_id":"12","label":"KOTA PADANG SIDEMPUAN","value":"KOTA PADANG SIDEMPUAN"},
    {"id":"1278","province_id":"12","label":"KOTA GUNUNGSITOLI","value":"KOTA GUNUNGSITOLI"},
    {"id":"1301","province_id":"13","label":"KAB. PESISIR SELATAN","value":"KAB. PESISIR SELATAN"},
    {"id":"1302","province_id":"13","label":"KAB. SOLOK","value":"KAB. SOLOK"},
    {"id":"1303","province_id":"13","label":"KAB. SIJUNJUNG","value":"KAB. SIJUNJUNG"},
    {"id":"1304","province_id":"13","label":"KAB. TANAH DATAR","value":"KAB. TANAH DATAR"},
    {"id":"1305","province_id":"13","label":"KAB. PADANG PARIAMAN","value":"KAB. PADANG PARIAMAN"},
    {"id":"1306","province_id":"13","label":"KAB. AGAM","value":"KAB. AGAM"},
    {"id":"1307","province_id":"13","label":"KAB. LIMA PULUH KOTA","value":"KAB. LIMA PULUH KOTA"},
    {"id":"1308","province_id":"13","label":"KAB. PASAMAN","value":"KAB. PASAMAN"},
    {"id":"1309","province_id":"13","label":"KAB. KEPULAUAN MENTAWAI","value":"KAB. KEPULAUAN MENTAWAI"},
    {"id":"1310","province_id":"13","label":"KAB. DHARMASRAYA","value":"KAB. DHARMASRAYA"},
    {"id":"1311","province_id":"13","label":"KAB. SOLOK SELATAN","value":"KAB. SOLOK SELATAN"},
    {"id":"1312","province_id":"13","label":"KAB. PASAMAN BARAT","value":"KAB. PASAMAN BARAT"},
    {"id":"1371","province_id":"13","label":"KOTA PADANG","value":"KOTA PADANG"},
    {"id":"1372","province_id":"13","label":"KOTA SOLOK","value":"KOTA SOLOK"},
    {"id":"1373","province_id":"13","label":"KOTA SAWAHLUNTO","value":"KOTA SAWAHLUNTO"},
    {"id":"1374","province_id":"13","label":"KOTA PADANG PANJANG","value":"KOTA PADANG PANJANG"},
    {"id":"1375","province_id":"13","label":"KOTA BUKITTINGGI","value":"KOTA BUKITTINGGI"},
    {"id":"1376","province_id":"13","label":"KOTA PAYAKUMBUH","value":"KOTA PAYAKUMBUH"},
    {"id":"1377","province_id":"13","label":"KOTA PARIAMAN","value":"KOTA PARIAMAN"},
    {"id":"1401","province_id":"14","label":"KAB. KAMPAR","value":"KAB. KAMPAR"},
    {"id":"1402","province_id":"14","label":"KAB. INDRAGIRI HULU","value":"KAB. INDRAGIRI HULU"},
    {"id":"1403","province_id":"14","label":"KAB. BENGKALIS","value":"KAB. BENGKALIS"},
    {"id":"1404","province_id":"14","label":"KAB. INDRAGIRI HILIR","value":"KAB. INDRAGIRI HILIR"},
    {"id":"1405","province_id":"14","label":"KAB. PELALAWAN","value":"KAB. PELALAWAN"},
    {"id":"1406","province_id":"14","label":"KAB. ROKAN HULU","value":"KAB. ROKAN HULU"},
    {"id":"1407","province_id":"14","label":"KAB. ROKAN HILIR","value":"KAB. ROKAN HILIR"},
    {"id":"1408","province_id":"14","label":"KAB. SIAK","value":"KAB. SIAK"},
    {"id":"1409","province_id":"14","label":"KAB. KUANTAN SINGINGI","value":"KAB. KUANTAN SINGINGI"},
    {"id":"1410","province_id":"14","label":"KAB. KEPULAUAN MERANTI","value":"KAB. KEPULAUAN MERANTI"},
    {"id":"1471","province_id":"14","label":"KOTA PEKANBARU","value":"KOTA PEKANBARU"},
    {"id":"1472","province_id":"14","label":"KOTA DUMAI","value":"KOTA DUMAI"},
    {"id":"1501","province_id":"15","label":"KAB. KERINCI","value":"KAB. KERINCI"},
    {"id":"1502","province_id":"15","label":"KAB. MERANGIN","value":"KAB. MERANGIN"},
    {"id":"1503","province_id":"15","label":"KAB. SAROLANGUN","value":"KAB. SAROLANGUN"},
    {"id":"1504","province_id":"15","label":"KAB. BATANGHARI","value":"KAB. BATANGHARI"},
    {"id":"1505","province_id":"15","label":"KAB. MUARO JAMBI","value":"KAB. MUARO JAMBI"},
    {"id":"1506","province_id":"15","label":"KAB. TANJUNG JABUNG BARAT","value":"KAB. TANJUNG JABUNG BARAT"},
    {"id":"1507","province_id":"15","label":"KAB. TANJUNG JABUNG TIMUR","value":"KAB. TANJUNG JABUNG TIMUR"},
    {"id":"1508","province_id":"15","label":"KAB. BUNGO","value":"KAB. BUNGO"},
    {"id":"1509","province_id":"15","label":"KAB. TEBO","value":"KAB. TEBO"},
    {"id":"1571","province_id":"15","label":"KOTA JAMBI","value":"KOTA JAMBI"},
    {"id":"1572","province_id":"15","label":"KOTA SUNGAI PENUH","value":"KOTA SUNGAI PENUH"},
    {"id":"1601","province_id":"16","label":"KAB. OGAN KOMERING ULU","value":"KAB. OGAN KOMERING ULU"},
    {"id":"1602","province_id":"16","label":"KAB. OGAN KOMERING ILIR","value":"KAB. OGAN KOMERING ILIR"},
    {"id":"1603","province_id":"16","label":"KAB. MUARA ENIM","value":"KAB. MUARA ENIM"},
    {"id":"1604","province_id":"16","label":"KAB. LAHAT","value":"KAB. LAHAT"},
    {"id":"1605","province_id":"16","label":"KAB. MUSI RAWAS","value":"KAB. MUSI RAWAS"},
    {"id":"1606","province_id":"16","label":"KAB. MUSI BANYUASIN","value":"KAB. MUSI BANYUASIN"},
    {"id":"1607","province_id":"16","label":"KAB. BANYUASIN","value":"KAB. BANYUASIN"},
    {"id":"1608","province_id":"16","label":"KAB. OGAN KOMERING ULU TIMUR","value":"KAB. OGAN KOMERING ULU TIMUR"},
    {"id":"1609","province_id":"16","label":"KAB. OGAN KOMERING ULU SELATAN","value":"KAB. OGAN KOMERING ULU SELATAN"},
    {"id":"1610","province_id":"16","label":"KAB. OGAN ILIR","value":"KAB. OGAN ILIR"},
    {"id":"1611","province_id":"16","label":"KAB. EMPAT LAWANG","value":"KAB. EMPAT LAWANG"},
    {"id":"1612","province_id":"16","label":"KAB. PENUKAL ABAB LEMATANG ILIR","value":"KAB. PENUKAL ABAB LEMATANG ILIR"},
    {"id":"1613","province_id":"16","label":"KAB. MUSI RAWAS UTARA","value":"KAB. MUSI RAWAS UTARA"},
    {"id":"1671","province_id":"16","label":"KOTA PALEMBANG","value":"KOTA PALEMBANG"},
    {"id":"1672","province_id":"16","label":"KOTA PAGAR ALAM","value":"KOTA PAGAR ALAM"},
    {"id":"1673","province_id":"16","label":"KOTA LUBUK LINGGAU","value":"KOTA LUBUK LINGGAU"},
    {"id":"1674","province_id":"16","label":"KOTA PRABUMULIH","value":"KOTA PRABUMULIH"},
    {"id":"1701","province_id":"17","label":"KAB. BENGKULU SELATAN","value":"KAB. BENGKULU SELATAN"},
    {"id":"1702","province_id":"17","label":"KAB. REJANG LEBONG","value":"KAB. REJANG LEBONG"},
    {"id":"1703","province_id":"17","label":"KAB. BENGKULU UTARA","value":"KAB. BENGKULU UTARA"},
    {"id":"1704","province_id":"17","label":"KAB. KAUR","value":"KAB. KAUR"},
    {"id":"1705","province_id":"17","label":"KAB. SELUMA","value":"KAB. SELUMA"},
    {"id":"1706","province_id":"17","label":"KAB. MUKO MUKO","value":"KAB. MUKO MUKO"},
    {"id":"1707","province_id":"17","label":"KAB. LEBONG","value":"KAB. LEBONG"},
    {"id":"1708","province_id":"17","label":"KAB. KEPAHIANG","value":"KAB. KEPAHIANG"},
    {"id":"1709","province_id":"17","label":"KAB. BENGKULU TENGAH","value":"KAB. BENGKULU TENGAH"},
    {"id":"1771","province_id":"17","label":"KOTA BENGKULU","value":"KOTA BENGKULU"},
    {"id":"1801","province_id":"18","label":"KAB. LAMPUNG SELATAN","value":"KAB. LAMPUNG SELATAN"},
    {"id":"1802","province_id":"18","label":"KAB. LAMPUNG TENGAH","value":"KAB. LAMPUNG TENGAH"},
    {"id":"1803","province_id":"18","label":"KAB. LAMPUNG UTARA","value":"KAB. LAMPUNG UTARA"},
    {"id":"1804","province_id":"18","label":"KAB. LAMPUNG BARAT","value":"KAB. LAMPUNG BARAT"},
    {"id":"1805","province_id":"18","label":"KAB. TULANG BAWANG","value":"KAB. TULANG BAWANG"},
    {"id":"1806","province_id":"18","label":"KAB. TANGGAMUS","value":"KAB. TANGGAMUS"},
    {"id":"1807","province_id":"18","label":"KAB. LAMPUNG TIMUR","value":"KAB. LAMPUNG TIMUR"},
    {"id":"1808","province_id":"18","label":"KAB. WAY KANAN","value":"KAB. WAY KANAN"},
    {"id":"1809","province_id":"18","label":"KAB. PESAWARAN","value":"KAB. PESAWARAN"},
    {"id":"1810","province_id":"18","label":"KAB. PRINGSEWU","value":"KAB. PRINGSEWU"},
    {"id":"1811","province_id":"18","label":"KAB. MESUJI","value":"KAB. MESUJI"},
    {"id":"1812","province_id":"18","label":"KAB. TULANG BAWANG BARAT","value":"KAB. TULANG BAWANG BARAT"},
    {"id":"1813","province_id":"18","label":"KAB. PESISIR BARAT","value":"KAB. PESISIR BARAT"},
    {"id":"1871","province_id":"18","label":"KOTA BANDAR LAMPUNG","value":"KOTA BANDAR LAMPUNG"},
    {"id":"1872","province_id":"18","label":"KOTA METRO","value":"KOTA METRO"},
    {"id":"1901","province_id":"19","label":"KAB. BANGKA","value":"KAB. BANGKA"},
    {"id":"1902","province_id":"19","label":"KAB. BELITUNG","value":"KAB. BELITUNG"},
    {"id":"1903","province_id":"19","label":"KAB. BANGKA SELATAN","value":"KAB. BANGKA SELATAN"},
    {"id":"1904","province_id":"19","label":"KAB. BANGKA TENGAH","value":"KAB. BANGKA TENGAH"},
    {"id":"1905","province_id":"19","label":"KAB. BANGKA BARAT","value":"KAB. BANGKA BARAT"},
    {"id":"1906","province_id":"19","label":"KAB. BELITUNG TIMUR","value":"KAB. BELITUNG TIMUR"},
    {"id":"1971","province_id":"19","label":"KOTA PANGKAL PINANG","value":"KOTA PANGKAL PINANG"},
    {"id":"2101","province_id":"21","label":"KAB. BINTAN","value":"KAB. BINTAN"},
    {"id":"2102","province_id":"21","label":"KAB. KARIMUN","value":"KAB. KARIMUN"},
    {"id":"2103","province_id":"21","label":"KAB. NATUNA","value":"KAB. NATUNA"},
    {"id":"2104","province_id":"21","label":"KAB. LINGGA","value":"KAB. LINGGA"},
    {"id":"2105","province_id":"21","label":"KAB. KEPULAUAN ANAMBAS","value":"KAB. KEPULAUAN ANAMBAS"},
    {"id":"2171","province_id":"21","label":"KOTA BATAM","value":"KOTA BATAM"},
    {"id":"2172","province_id":"21","label":"KOTA TANJUNG PINANG","value":"KOTA TANJUNG PINANG"},
    {"id":"3101","province_id":"31","label":"KAB. ADM. KEP. SERIBU","value":"KAB. ADM. KEP. SERIBU"},
    {"id":"3171","province_id":"31","label":"KOTA ADM. JAKARTA PUSAT","value":"KOTA ADM. JAKARTA PUSAT"},
    {"id":"3172","province_id":"31","label":"KOTA ADM. JAKARTA UTARA","value":"KOTA ADM. JAKARTA UTARA"},
    {"id":"3173","province_id":"31","label":"KOTA ADM. JAKARTA BARAT","value":"KOTA ADM. JAKARTA BARAT"},
    {"id":"3174","province_id":"31","label":"KOTA ADM. JAKARTA SELATAN","value":"KOTA ADM. JAKARTA SELATAN"},
    {"id":"3175","province_id":"31","label":"KOTA ADM. JAKARTA TIMUR","value":"KOTA ADM. JAKARTA TIMUR"},
    {"id":"3201","province_id":"32","label":"KAB. BOGOR","value":"KAB. BOGOR"},
    {"id":"3202","province_id":"32","label":"KAB. SUKABUMI","value":"KAB. SUKABUMI"},
    {"id":"3203","province_id":"32","label":"KAB. CIANJUR","value":"KAB. CIANJUR"},
    {"id":"3204","province_id":"32","label":"KAB. BANDUNG","value":"KAB. BANDUNG"},
    {"id":"3205","province_id":"32","label":"KAB. GARUT","value":"KAB. GARUT"},
    {"id":"3206","province_id":"32","label":"KAB. TASIKMALAYA","value":"KAB. TASIKMALAYA"},
    {"id":"3207","province_id":"32","label":"KAB. CIAMIS","value":"KAB. CIAMIS"},
    {"id":"3208","province_id":"32","label":"KAB. KUNINGAN","value":"KAB. KUNINGAN"},
    {"id":"3209","province_id":"32","label":"KAB. CIREBON","value":"KAB. CIREBON"},
    {"id":"3210","province_id":"32","label":"KAB. MAJALENGKA","value":"KAB. MAJALENGKA"},
    {"id":"3211","province_id":"32","label":"KAB. SUMEDANG","value":"KAB. SUMEDANG"},
    {"id":"3212","province_id":"32","label":"KAB. INDRAMAYU","value":"KAB. INDRAMAYU"},
    {"id":"3213","province_id":"32","label":"KAB. SUBANG","value":"KAB. SUBANG"},
    {"id":"3214","province_id":"32","label":"KAB. PURWAKARTA","value":"KAB. PURWAKARTA"},
    {"id":"3215","province_id":"32","label":"KAB. KARAWANG","value":"KAB. KARAWANG"},
    {"id":"3216","province_id":"32","label":"KAB. BEKASI","value":"KAB. BEKASI"},
    {"id":"3217","province_id":"32","label":"KAB. BANDUNG BARAT","value":"KAB. BANDUNG BARAT"},
    {"id":"3218","province_id":"32","label":"KAB. PANGANDARAN","value":"KAB. PANGANDARAN"},
    {"id":"3271","province_id":"32","label":"KOTA BOGOR","value":"KOTA BOGOR"},
    {"id":"3272","province_id":"32","label":"KOTA SUKABUMI","value":"KOTA SUKABUMI"},
    {"id":"3273","province_id":"32","label":"KOTA BANDUNG","value":"KOTA BANDUNG"},
    {"id":"3274","province_id":"32","label":"KOTA CIREBON","value":"KOTA CIREBON"},
    {"id":"3275","province_id":"32","label":"KOTA BEKASI","value":"KOTA BEKASI"},
    {"id":"3276","province_id":"32","label":"KOTA DEPOK","value":"KOTA DEPOK"},
    {"id":"3277","province_id":"32","label":"KOTA CIMAHI","value":"KOTA CIMAHI"},
    {"id":"3278","province_id":"32","label":"KOTA TASIKMALAYA","value":"KOTA TASIKMALAYA"},
    {"id":"3279","province_id":"32","label":"KOTA BANJAR","value":"KOTA BANJAR"},
    {"id":"3301","province_id":"33","label":"KAB. CILACAP","value":"KAB. CILACAP"},
    {"id":"3302","province_id":"33","label":"KAB. BANYUMAS","value":"KAB. BANYUMAS"},
    {"id":"3303","province_id":"33","label":"KAB. PURBALINGGA","value":"KAB. PURBALINGGA"},
    {"id":"3304","province_id":"33","label":"KAB. BANJARNEGARA","value":"KAB. BANJARNEGARA"},
    {"id":"3305","province_id":"33","label":"KAB. KEBUMEN","value":"KAB. KEBUMEN"},
    {"id":"3306","province_id":"33","label":"KAB. PURWOREJO","value":"KAB. PURWOREJO"},
    {"id":"3307","province_id":"33","label":"KAB. WONOSOBO","value":"KAB. WONOSOBO"},
    {"id":"3308","province_id":"33","label":"KAB. MAGELANG","value":"KAB. MAGELANG"},
    {"id":"3309","province_id":"33","label":"KAB. BOYOLALI","value":"KAB. BOYOLALI"},
    {"id":"3310","province_id":"33","label":"KAB. KLATEN","value":"KAB. KLATEN"},
    {"id":"3311","province_id":"33","label":"KAB. SUKOHARJO","value":"KAB. SUKOHARJO"},
    {"id":"3312","province_id":"33","label":"KAB. WONOGIRI","value":"KAB. WONOGIRI"},
    {"id":"3313","province_id":"33","label":"KAB. KARANGANYAR","value":"KAB. KARANGANYAR"},
    {"id":"3314","province_id":"33","label":"KAB. SRAGEN","value":"KAB. SRAGEN"},
    {"id":"3315","province_id":"33","label":"KAB. GROBOGAN","value":"KAB. GROBOGAN"},
    {"id":"3316","province_id":"33","label":"KAB. BLORA","value":"KAB. BLORA"},
    {"id":"3317","province_id":"33","label":"KAB. REMBANG","value":"KAB. REMBANG"},
    {"id":"3318","province_id":"33","label":"KAB. PATI","value":"KAB. PATI"},
    {"id":"3319","province_id":"33","label":"KAB. KUDUS","value":"KAB. KUDUS"},
    {"id":"3320","province_id":"33","label":"KAB. JEPARA","value":"KAB. JEPARA"},
    {"id":"3321","province_id":"33","label":"KAB. DEMAK","value":"KAB. DEMAK"},
    {"id":"3322","province_id":"33","label":"KAB. SEMARANG","value":"KAB. SEMARANG"},
    {"id":"3323","province_id":"33","label":"KAB. TEMANGGUNG","value":"KAB. TEMANGGUNG"},
    {"id":"3324","province_id":"33","label":"KAB. KENDAL","value":"KAB. KENDAL"},
    {"id":"3325","province_id":"33","label":"KAB. BATANG","value":"KAB. BATANG"},
    {"id":"3326","province_id":"33","label":"KAB. PEKALONGAN","value":"KAB. PEKALONGAN"},
    {"id":"3327","province_id":"33","label":"KAB. PEMALANG","value":"KAB. PEMALANG"},
    {"id":"3328","province_id":"33","label":"KAB. TEGAL","value":"KAB. TEGAL"},
    {"id":"3329","province_id":"33","label":"KAB. BREBES","value":"KAB. BREBES"},
    {"id":"3371","province_id":"33","label":"KOTA MAGELANG","value":"KOTA MAGELANG"},
    {"id":"3372","province_id":"33","label":"KOTA SURAKARTA","value":"KOTA SURAKARTA"},
    {"id":"3373","province_id":"33","label":"KOTA SALATIGA","value":"KOTA SALATIGA"},
    {"id":"3374","province_id":"33","label":"KOTA SEMARANG","value":"KOTA SEMARANG"},
    {"id":"3375","province_id":"33","label":"KOTA PEKALONGAN","value":"KOTA PEKALONGAN"},
    {"id":"3376","province_id":"33","label":"KOTA TEGAL","value":"KOTA TEGAL"},
    {"id":"3401","province_id":"34","label":"KAB. KULON PROGO","value":"KAB. KULON PROGO"},
    {"id":"3402","province_id":"34","label":"KAB. BANTUL","value":"KAB. BANTUL"},
    {"id":"3403","province_id":"34","label":"KAB. GUNUNGKIDUL","value":"KAB. GUNUNGKIDUL"},
    {"id":"3404","province_id":"34","label":"KAB. SLEMAN","value":"KAB. SLEMAN"},
    {"id":"3471","province_id":"34","label":"KOTA YOGYAKARTA","value":"KOTA YOGYAKARTA"},
    {"id":"3501","province_id":"35","label":"KAB. PACITAN","value":"KAB. PACITAN"},
    {"id":"3502","province_id":"35","label":"KAB. PONOROGO","value":"KAB. PONOROGO"},
    {"id":"3503","province_id":"35","label":"KAB. TRENGGALEK","value":"KAB. TRENGGALEK"},
    {"id":"3504","province_id":"35","label":"KAB. TULUNGAGUNG","value":"KAB. TULUNGAGUNG"},
    {"id":"3505","province_id":"35","label":"KAB. BLITAR","value":"KAB. BLITAR"},
    {"id":"3506","province_id":"35","label":"KAB. KEDIRI","value":"KAB. KEDIRI"},
    {"id":"3507","province_id":"35","label":"KAB. MALANG","value":"KAB. MALANG"},
    {"id":"3508","province_id":"35","label":"KAB. LUMAJANG","value":"KAB. LUMAJANG"},
    {"id":"3509","province_id":"35","label":"KAB. JEMBER","value":"KAB. JEMBER"},
    {"id":"3510","province_id":"35","label":"KAB. BANYUWANGI","value":"KAB. BANYUWANGI"},
    {"id":"3511","province_id":"35","label":"KAB. BONDOWOSO","value":"KAB. BONDOWOSO"},
    {"id":"3512","province_id":"35","label":"KAB. SITUBONDO","value":"KAB. SITUBONDO"},
    {"id":"3513","province_id":"35","label":"KAB. PROBOLINGGO","value":"KAB. PROBOLINGGO"},
    {"id":"3514","province_id":"35","label":"KAB. PASURUAN","value":"KAB. PASURUAN"},
    {"id":"3515","province_id":"35","label":"KAB. SIDOARJO","value":"KAB. SIDOARJO"},
    {"id":"3516","province_id":"35","label":"KAB. MOJOKERTO","value":"KAB. MOJOKERTO"},
    {"id":"3517","province_id":"35","label":"KAB. JOMBANG","value":"KAB. JOMBANG"},
    {"id":"3518","province_id":"35","label":"KAB. NGANJUK","value":"KAB. NGANJUK"},
    {"id":"3519","province_id":"35","label":"KAB. MADIUN","value":"KAB. MADIUN"},
    {"id":"3520","province_id":"35","label":"KAB. MAGETAN","value":"KAB. MAGETAN"},
    {"id":"3521","province_id":"35","label":"KAB. NGAWI","value":"KAB. NGAWI"},
    {"id":"3522","province_id":"35","label":"KAB. BOJONEGORO","value":"KAB. BOJONEGORO"},
    {"id":"3523","province_id":"35","label":"KAB. TUBAN","value":"KAB. TUBAN"},
    {"id":"3524","province_id":"35","label":"KAB. LAMONGAN","value":"KAB. LAMONGAN"},
    {"id":"3525","province_id":"35","label":"KAB. GRESIK","value":"KAB. GRESIK"},
    {"id":"3526","province_id":"35","label":"KAB. BANGKALAN","value":"KAB. BANGKALAN"},
    {"id":"3527","province_id":"35","label":"KAB. SAMPANG","value":"KAB. SAMPANG"},
    {"id":"3528","province_id":"35","label":"KAB. PAMEKASAN","value":"KAB. PAMEKASAN"},
    {"id":"3529","province_id":"35","label":"KAB. SUMENEP","value":"KAB. SUMENEP"},
    {"id":"3571","province_id":"35","label":"KOTA KEDIRI","value":"KOTA KEDIRI"},
    {"id":"3572","province_id":"35","label":"KOTA BLITAR","value":"KOTA BLITAR"},
    {"id":"3573","province_id":"35","label":"KOTA MALANG","value":"KOTA MALANG"},
    {"id":"3574","province_id":"35","label":"KOTA PROBOLINGGO","value":"KOTA PROBOLINGGO"},
    {"id":"3575","province_id":"35","label":"KOTA PASURUAN","value":"KOTA PASURUAN"},
    {"id":"3576","province_id":"35","label":"KOTA MOJOKERTO","value":"KOTA MOJOKERTO"},
    {"id":"3577","province_id":"35","label":"KOTA MADIUN","value":"KOTA MADIUN"},
    {"id":"3578","province_id":"35","label":"KOTA SURABAYA","value":"KOTA SURABAYA"},
    {"id":"3579","province_id":"35","label":"KOTA BATU","value":"KOTA BATU"},
    {"id":"3601","province_id":"36","label":"KAB. PANDEGLANG","value":"KAB. PANDEGLANG"},
    {"id":"3602","province_id":"36","label":"KAB. LEBAK","value":"KAB. LEBAK"},
    {"id":"3603","province_id":"36","label":"KAB. TANGERANG","value":"KAB. TANGERANG"},
    {"id":"3604","province_id":"36","label":"KAB. SERANG","value":"KAB. SERANG"},
    {"id":"3671","province_id":"36","label":"KOTA TANGERANG","value":"KOTA TANGERANG"},
    {"id":"3672","province_id":"36","label":"KOTA CILEGON","value":"KOTA CILEGON"},
    {"id":"3673","province_id":"36","label":"KOTA SERANG","value":"KOTA SERANG"},
    {"id":"3674","province_id":"36","label":"KOTA TANGERANG SELATAN","value":"KOTA TANGERANG SELATAN"},
    {"id":"5101","province_id":"51","label":"KAB. JEMBRANA","value":"KAB. JEMBRANA"},
    {"id":"5102","province_id":"51","label":"KAB. TABANAN","value":"KAB. TABANAN"},
    {"id":"5103","province_id":"51","label":"KAB. BADUNG","value":"KAB. BADUNG"},
    {"id":"5104","province_id":"51","label":"KAB. GIANYAR","value":"KAB. GIANYAR"},
    {"id":"5105","province_id":"51","label":"KAB. KLUNGKUNG","value":"KAB. KLUNGKUNG"},
    {"id":"5106","province_id":"51","label":"KAB. BANGLI","value":"KAB. BANGLI"},
    {"id":"5107","province_id":"51","label":"KAB. KARANGASEM","value":"KAB. KARANGASEM"},
    {"id":"5108","province_id":"51","label":"KAB. BULELENG","value":"KAB. BULELENG"},
    {"id":"5171","province_id":"51","label":"KOTA DENPASAR","value":"KOTA DENPASAR"},
    {"id":"5201","province_id":"52","label":"KAB. LOMBOK BARAT","value":"KAB. LOMBOK BARAT"},
    {"id":"5202","province_id":"52","label":"KAB. LOMBOK TENGAH","value":"KAB. LOMBOK TENGAH"},
    {"id":"5203","province_id":"52","label":"KAB. LOMBOK TIMUR","value":"KAB. LOMBOK TIMUR"},
    {"id":"5204","province_id":"52","label":"KAB. SUMBAWA","value":"KAB. SUMBAWA"},
    {"id":"5205","province_id":"52","label":"KAB. DOMPU","value":"KAB. DOMPU"},
    {"id":"5206","province_id":"52","label":"KAB. BIMA","value":"KAB. BIMA"},
    {"id":"5207","province_id":"52","label":"KAB. SUMBAWA BARAT","value":"KAB. SUMBAWA BARAT"},
    {"id":"5208","province_id":"52","label":"KAB. LOMBOK UTARA","value":"KAB. LOMBOK UTARA"},
    {"id":"5271","province_id":"52","label":"KOTA MATARAM","value":"KOTA MATARAM"},
    {"id":"5272","province_id":"52","label":"KOTA BIMA","value":"KOTA BIMA"},
    {"id":"5301","province_id":"53","label":"KAB. KUPANG","value":"KAB. KUPANG"},
    {"id":"5302","province_id":"53","label":"KAB TIMOR TENGAH SELATAN","value":"KAB TIMOR TENGAH SELATAN"},
    {"id":"5303","province_id":"53","label":"KAB. TIMOR TENGAH UTARA","value":"KAB. TIMOR TENGAH UTARA"},
    {"id":"5304","province_id":"53","label":"KAB. BELU","value":"KAB. BELU"},
    {"id":"5305","province_id":"53","label":"KAB. ALOR","value":"KAB. ALOR"},
    {"id":"5306","province_id":"53","label":"KAB. FLORES TIMUR","value":"KAB. FLORES TIMUR"},
    {"id":"5307","province_id":"53","label":"KAB. SIKKA","value":"KAB. SIKKA"},
    {"id":"5308","province_id":"53","label":"KAB. ENDE","value":"KAB. ENDE"},
    {"id":"5309","province_id":"53","label":"KAB. NGADA","value":"KAB. NGADA"},
    {"id":"5310","province_id":"53","label":"KAB. MANGGARAI","value":"KAB. MANGGARAI"},
    {"id":"5311","province_id":"53","label":"KAB. SUMBA TIMUR","value":"KAB. SUMBA TIMUR"},
    {"id":"5312","province_id":"53","label":"KAB. SUMBA BARAT","value":"KAB. SUMBA BARAT"},
    {"id":"5313","province_id":"53","label":"KAB. LEMBATA","value":"KAB. LEMBATA"},
    {"id":"5314","province_id":"53","label":"KAB. ROTE NDAO","value":"KAB. ROTE NDAO"},
    {"id":"5315","province_id":"53","label":"KAB. MANGGARAI BARAT","value":"KAB. MANGGARAI BARAT"},
    {"id":"5316","province_id":"53","label":"KAB. NAGEKEO","value":"KAB. NAGEKEO"},
    {"id":"5317","province_id":"53","label":"KAB. SUMBA TENGAH","value":"KAB. SUMBA TENGAH"},
    {"id":"5318","province_id":"53","label":"KAB. SUMBA BARAT DAYA","value":"KAB. SUMBA BARAT DAYA"},
    {"id":"5319","province_id":"53","label":"KAB. MANGGARAI TIMUR","value":"KAB. MANGGARAI TIMUR"},
    {"id":"5320","province_id":"53","label":"KAB. SABU RAIJUA","value":"KAB. SABU RAIJUA"},
    {"id":"5321","province_id":"53","label":"KAB. MALAKA","value":"KAB. MALAKA"},
    {"id":"5371","province_id":"53","label":"KOTA KUPANG","value":"KOTA KUPANG"},
    {"id":"6101","province_id":"61","label":"KAB. SAMBAS","value":"KAB. SAMBAS"},
    {"id":"6102","province_id":"61","label":"KAB. MEMPAWAH","value":"KAB. MEMPAWAH"},
    {"id":"6103","province_id":"61","label":"KAB. SANGGAU","value":"KAB. SANGGAU"},
    {"id":"6104","province_id":"61","label":"KAB. KETAPANG","value":"KAB. KETAPANG"},
    {"id":"6105","province_id":"61","label":"KAB. SINTANG","value":"KAB. SINTANG"},
    {"id":"6106","province_id":"61","label":"KAB. KAPUAS HULU","value":"KAB. KAPUAS HULU"},
    {"id":"6107","province_id":"61","label":"KAB. BENGKAYANG","value":"KAB. BENGKAYANG"},
    {"id":"6108","province_id":"61","label":"KAB. LANDAK","value":"KAB. LANDAK"},
    {"id":"6109","province_id":"61","label":"KAB. SEKADAU","value":"KAB. SEKADAU"},
    {"id":"6110","province_id":"61","label":"KAB. MELAWI","value":"KAB. MELAWI"},
    {"id":"6111","province_id":"61","label":"KAB. KAYONG UTARA","value":"KAB. KAYONG UTARA"},
    {"id":"6112","province_id":"61","label":"KAB. KUBU RAYA","value":"KAB. KUBU RAYA"},
    {"id":"6171","province_id":"61","label":"KOTA PONTIANAK","value":"KOTA PONTIANAK"},
    {"id":"6172","province_id":"61","label":"KOTA SINGKAWANG","value":"KOTA SINGKAWANG"},
    {"id":"6201","province_id":"62","label":"KAB. KOTAWARINGIN BARAT","value":"KAB. KOTAWARINGIN BARAT"},
    {"id":"6202","province_id":"62","label":"KAB. KOTAWARINGIN TIMUR","value":"KAB. KOTAWARINGIN TIMUR"},
    {"id":"6203","province_id":"62","label":"KAB. KAPUAS","value":"KAB. KAPUAS"},
    {"id":"6204","province_id":"62","label":"KAB. BARITO SELATAN","value":"KAB. BARITO SELATAN"},
    {"id":"6205","province_id":"62","label":"KAB. BARITO UTARA","value":"KAB. BARITO UTARA"},
    {"id":"6206","province_id":"62","label":"KAB. KATINGAN","value":"KAB. KATINGAN"},
    {"id":"6207","province_id":"62","label":"KAB. SERUYAN","value":"KAB. SERUYAN"},
    {"id":"6208","province_id":"62","label":"KAB. SUKAMARA","value":"KAB. SUKAMARA"},
    {"id":"6209","province_id":"62","label":"KAB. LAMANDAU","value":"KAB. LAMANDAU"},
    {"id":"6210","province_id":"62","label":"KAB. GUNUNG MAS","value":"KAB. GUNUNG MAS"},
    {"id":"6211","province_id":"62","label":"KAB. PULANG PISAU","value":"KAB. PULANG PISAU"},
    {"id":"6212","province_id":"62","label":"KAB. MURUNG RAYA","value":"KAB. MURUNG RAYA"},
    {"id":"6213","province_id":"62","label":"KAB. BARITO TIMUR","value":"KAB. BARITO TIMUR"},
    {"id":"6271","province_id":"62","label":"KOTA PALANGKARAYA","value":"KOTA PALANGKARAYA"},
    {"id":"6301","province_id":"63","label":"KAB. TANAH LAUT","value":"KAB. TANAH LAUT"},
    {"id":"6302","province_id":"63","label":"KAB. KOTABARU","value":"KAB. KOTABARU"},
    {"id":"6303","province_id":"63","label":"KAB. BANJAR","value":"KAB. BANJAR"},
    {"id":"6304","province_id":"63","label":"KAB. BARITO KUALA","value":"KAB. BARITO KUALA"},
    {"id":"6305","province_id":"63","label":"KAB. TAPIN","value":"KAB. TAPIN"},
    {"id":"6306","province_id":"63","label":"KAB. HULU SUNGAI SELATAN","value":"KAB. HULU SUNGAI SELATAN"},
    {"id":"6307","province_id":"63","label":"KAB. HULU SUNGAI TENGAH","value":"KAB. HULU SUNGAI TENGAH"},
    {"id":"6308","province_id":"63","label":"KAB. HULU SUNGAI UTARA","value":"KAB. HULU SUNGAI UTARA"},
    {"id":"6309","province_id":"63","label":"KAB. TABALONG","value":"KAB. TABALONG"},
    {"id":"6310","province_id":"63","label":"KAB. TANAH BUMBU","value":"KAB. TANAH BUMBU"},
    {"id":"6311","province_id":"63","label":"KAB. BALANGAN","value":"KAB. BALANGAN"},
    {"id":"6371","province_id":"63","label":"KOTA BANJARMASIN","value":"KOTA BANJARMASIN"},
    {"id":"6372","province_id":"63","label":"KOTA BANJARBARU","value":"KOTA BANJARBARU"},
    {"id":"6401","province_id":"64","label":"KAB. PASER","value":"KAB. PASER"},
    {"id":"6402","province_id":"64","label":"KAB. KUTAI KARTANEGARA","value":"KAB. KUTAI KARTANEGARA"},
    {"id":"6403","province_id":"64","label":"KAB. BERAU","value":"KAB. BERAU"},
    {"id":"6407","province_id":"64","label":"KAB. KUTAI BARAT","value":"KAB. KUTAI BARAT"},
    {"id":"6408","province_id":"64","label":"KAB. KUTAI TIMUR","value":"KAB. KUTAI TIMUR"},
    {"id":"6409","province_id":"64","label":"KAB. PENAJAM PASER UTARA","value":"KAB. PENAJAM PASER UTARA"},
    {"id":"6411","province_id":"64","label":"KAB. MAHAKAM ULU","value":"KAB. MAHAKAM ULU"},
    {"id":"6471","province_id":"64","label":"KOTA BALIKPAPAN","value":"KOTA BALIKPAPAN"},
    {"id":"6472","province_id":"64","label":"KOTA SAMARINDA","value":"KOTA SAMARINDA"},
    {"id":"6474","province_id":"64","label":"KOTA BONTANG","value":"KOTA BONTANG"},
    {"id":"6501","province_id":"65","label":"KAB. BULUNGAN","value":"KAB. BULUNGAN"},
    {"id":"6502","province_id":"65","label":"KAB. MALINAU","value":"KAB. MALINAU"},
    {"id":"6503","province_id":"65","label":"KAB. NUNUKAN","value":"KAB. NUNUKAN"},
    {"id":"6504","province_id":"65","label":"KAB. TANA TIDUNG","value":"KAB. TANA TIDUNG"},
    {"id":"6571","province_id":"65","label":"KOTA TARAKAN","value":"KOTA TARAKAN"},
    {"id":"7101","province_id":"71","label":"KAB. BOLAANG MONGONDOW","value":"KAB. BOLAANG MONGONDOW"},
    {"id":"7102","province_id":"71","label":"KAB. MINAHASA","value":"KAB. MINAHASA"},
    {"id":"7103","province_id":"71","label":"KAB. KEPULAUAN SANGIHE","value":"KAB. KEPULAUAN SANGIHE"},
    {"id":"7104","province_id":"71","label":"KAB. KEPULAUAN TALAUD","value":"KAB. KEPULAUAN TALAUD"},
    {"id":"7105","province_id":"71","label":"KAB. MINAHASA SELATAN","value":"KAB. MINAHASA SELATAN"},
    {"id":"7106","province_id":"71","label":"KAB. MINAHASA UTARA","value":"KAB. MINAHASA UTARA"},
    {"id":"7107","province_id":"71","label":"KAB. MINAHASA TENGGARA","value":"KAB. MINAHASA TENGGARA"},
    {"id":"7108","province_id":"71","label":"KAB. BOLAANG MONGONDOW UTARA","value":"KAB. BOLAANG MONGONDOW UTARA"},
    {"id":"7109","province_id":"71","label":"KAB. KEP. SIAU TAGULANDANG BIARO","value":"KAB. KEP. SIAU TAGULANDANG BIARO"},
    {"id":"7110","province_id":"71","label":"KAB. BOLAANG MONGONDOW TIMUR","value":"KAB. BOLAANG MONGONDOW TIMUR"},
    {"id":"7111","province_id":"71","label":"KAB. BOLAANG MONGONDOW SELATAN","value":"KAB. BOLAANG MONGONDOW SELATAN"},
    {"id":"7171","province_id":"71","label":"KOTA MANADO","value":"KOTA MANADO"},
    {"id":"7172","province_id":"71","label":"KOTA BITUNG","value":"KOTA BITUNG"},
    {"id":"7173","province_id":"71","label":"KOTA TOMOHON","value":"KOTA TOMOHON"},
    {"id":"7174","province_id":"71","label":"KOTA KOTAMOBAGU","value":"KOTA KOTAMOBAGU"},
    {"id":"7201","province_id":"72","label":"KAB. BANGGAI","value":"KAB. BANGGAI"},
    {"id":"7202","province_id":"72","label":"KAB. POSO","value":"KAB. POSO"},
    {"id":"7203","province_id":"72","label":"KAB. DONGGALA","value":"KAB. DONGGALA"},
    {"id":"7204","province_id":"72","label":"KAB. TOLI TOLI","value":"KAB. TOLI TOLI"},
    {"id":"7205","province_id":"72","label":"KAB. BUOL","value":"KAB. BUOL"},
    {"id":"7206","province_id":"72","label":"KAB. MOROWALI","value":"KAB. MOROWALI"},
    {"id":"7207","province_id":"72","label":"KAB. BANGGAI KEPULAUAN","value":"KAB. BANGGAI KEPULAUAN"},
    {"id":"7208","province_id":"72","label":"KAB. PARIGI MOUTONG","value":"KAB. PARIGI MOUTONG"},
    {"id":"7209","province_id":"72","label":"KAB. TOJO UNA UNA","value":"KAB. TOJO UNA UNA"},
    {"id":"7210","province_id":"72","label":"KAB. SIGI","value":"KAB. SIGI"},
    {"id":"7211","province_id":"72","label":"KAB. BANGGAI LAUT","value":"KAB. BANGGAI LAUT"},
    {"id":"7212","province_id":"72","label":"KAB. MOROWALI UTARA","value":"KAB. MOROWALI UTARA"},
    {"id":"7271","province_id":"72","label":"KOTA PALU","value":"KOTA PALU"},
    {"id":"7301","province_id":"73","label":"KAB. KEPULAUAN SELAYAR","value":"KAB. KEPULAUAN SELAYAR"},
    {"id":"7302","province_id":"73","label":"KAB. BULUKUMBA","value":"KAB. BULUKUMBA"},
    {"id":"7303","province_id":"73","label":"KAB. BANTAENG","value":"KAB. BANTAENG"},
    {"id":"7304","province_id":"73","label":"KAB. JENEPONTO","value":"KAB. JENEPONTO"},
    {"id":"7305","province_id":"73","label":"KAB. TAKALAR","value":"KAB. TAKALAR"},
    {"id":"7306","province_id":"73","label":"KAB. GOWA","value":"KAB. GOWA"},
    {"id":"7307","province_id":"73","label":"KAB. SINJAI","value":"KAB. SINJAI"},
    {"id":"7308","province_id":"73","label":"KAB. BONE","value":"KAB. BONE"},
    {"id":"7309","province_id":"73","label":"KAB. MAROS","value":"KAB. MAROS"},
    {"id":"7310","province_id":"73","label":"KAB. PANGKAJENE KEPULAUAN","value":"KAB. PANGKAJENE KEPULAUAN"},
    {"id":"7311","province_id":"73","label":"KAB. BARRU","value":"KAB. BARRU"},
    {"id":"7312","province_id":"73","label":"KAB. SOPPENG","value":"KAB. SOPPENG"},
    {"id":"7313","province_id":"73","label":"KAB. WAJO","value":"KAB. WAJO"},
    {"id":"7314","province_id":"73","label":"KAB. SIDENRENG RAPPANG","value":"KAB. SIDENRENG RAPPANG"},
    {"id":"7315","province_id":"73","label":"KAB. PINRANG","value":"KAB. PINRANG"},
    {"id":"7316","province_id":"73","label":"KAB. ENREKANG","value":"KAB. ENREKANG"},
    {"id":"7317","province_id":"73","label":"KAB. LUWU","value":"KAB. LUWU"},
    {"id":"7318","province_id":"73","label":"KAB. TANA TORAJA","value":"KAB. TANA TORAJA"},
    {"id":"7322","province_id":"73","label":"KAB. LUWU UTARA","value":"KAB. LUWU UTARA"},
    {"id":"7324","province_id":"73","label":"KAB. LUWU TIMUR","value":"KAB. LUWU TIMUR"},
    {"id":"7326","province_id":"73","label":"KAB. TORAJA UTARA","value":"KAB. TORAJA UTARA"},
    {"id":"7371","province_id":"73","label":"KOTA MAKASSAR","value":"KOTA MAKASSAR"},
    {"id":"7372","province_id":"73","label":"KOTA PARE PARE","value":"KOTA PARE PARE"},
    {"id":"7373","province_id":"73","label":"KOTA PALOPO","value":"KOTA PALOPO"},
    {"id":"7401","province_id":"74","label":"KAB. KOLAKA","value":"KAB. KOLAKA"},
    {"id":"7402","province_id":"74","label":"KAB. KONAWE","value":"KAB. KONAWE"},
    {"id":"7403","province_id":"74","label":"KAB. MUNA","value":"KAB. MUNA"},
    {"id":"7404","province_id":"74","label":"KAB. BUTON","value":"KAB. BUTON"},
    {"id":"7405","province_id":"74","label":"KAB. KONAWE SELATAN","value":"KAB. KONAWE SELATAN"},
    {"id":"7406","province_id":"74","label":"KAB. BOMBANA","value":"KAB. BOMBANA"},
    {"id":"7407","province_id":"74","label":"KAB. WAKATOBI","value":"KAB. WAKATOBI"},
    {"id":"7408","province_id":"74","label":"KAB. KOLAKA UTARA","value":"KAB. KOLAKA UTARA"},
    {"id":"7409","province_id":"74","label":"KAB. KONAWE UTARA","value":"KAB. KONAWE UTARA"},
    {"id":"7410","province_id":"74","label":"KAB. BUTON UTARA","value":"KAB. BUTON UTARA"},
    {"id":"7411","province_id":"74","label":"KAB. KOLAKA TIMUR","value":"KAB. KOLAKA TIMUR"},
    {"id":"7412","province_id":"74","label":"KAB. KONAWE KEPULAUAN","value":"KAB. KONAWE KEPULAUAN"},
    {"id":"7413","province_id":"74","label":"KAB. MUNA BARAT","value":"KAB. MUNA BARAT"},
    {"id":"7414","province_id":"74","label":"KAB. BUTON TENGAH","value":"KAB. BUTON TENGAH"},
    {"id":"7415","province_id":"74","label":"KAB. BUTON SELATAN","value":"KAB. BUTON SELATAN"},
    {"id":"7471","province_id":"74","label":"KOTA KENDARI","value":"KOTA KENDARI"},
    {"id":"7472","province_id":"74","label":"KOTA BAU BAU","value":"KOTA BAU BAU"},
    {"id":"7501","province_id":"75","label":"KAB. GORONTALO","value":"KAB. GORONTALO"},
    {"id":"7502","province_id":"75","label":"KAB. BOALEMO","value":"KAB. BOALEMO"},
    {"id":"7503","province_id":"75","label":"KAB. BONE BOLANGO","value":"KAB. BONE BOLANGO"},
    {"id":"7504","province_id":"75","label":"KAB. PAHUWATO","value":"KAB. PAHUWATO"},
    {"id":"7505","province_id":"75","label":"KAB. GORONTALO UTARA","value":"KAB. GORONTALO UTARA"},
    {"id":"7571","province_id":"75","label":"KOTA GORONTALO","value":"KOTA GORONTALO"},
    {"id":"7601","province_id":"76","label":"KAB. PASANGKAYU","value":"KAB. PASANGKAYU"},
    {"id":"7602","province_id":"76","label":"KAB. MAMUJU","value":"KAB. MAMUJU"},
    {"id":"7603","province_id":"76","label":"KAB. MAMASA","value":"KAB. MAMASA"},
    {"id":"7604","province_id":"76","label":"KAB. POLEWALI MANDAR","value":"KAB. POLEWALI MANDAR"},
    {"id":"7605","province_id":"76","label":"KAB. MAJENE","value":"KAB. MAJENE"},
    {"id":"7606","province_id":"76","label":"KAB. MAMUJU TENGAH","value":"KAB. MAMUJU TENGAH"},
    {"id":"8101","province_id":"81","label":"KAB. MALUKU TENGAH","value":"KAB. MALUKU TENGAH"},
    {"id":"8102","province_id":"81","label":"KAB. MALUKU TENGGARA","value":"KAB. MALUKU TENGGARA"},
    {"id":"8103","province_id":"81","label":"KAB. KEPULAUAN TANIMBAR","value":"KAB. KEPULAUAN TANIMBAR"},
    {"id":"8104","province_id":"81","label":"KAB. BURU","value":"KAB. BURU"},
    {"id":"8105","province_id":"81","label":"KAB. SERAM BAGIAN TIMUR","value":"KAB. SERAM BAGIAN TIMUR"},
    {"id":"8106","province_id":"81","label":"KAB. SERAM BAGIAN BARAT","value":"KAB. SERAM BAGIAN BARAT"},
    {"id":"8107","province_id":"81","label":"KAB. KEPULAUAN ARU","value":"KAB. KEPULAUAN ARU"},
    {"id":"8108","province_id":"81","label":"KAB. MALUKU BARAT DAYA","value":"KAB. MALUKU BARAT DAYA"},
    {"id":"8109","province_id":"81","label":"KAB. BURU SELATAN","value":"KAB. BURU SELATAN"},
    {"id":"8171","province_id":"81","label":"KOTA AMBON","value":"KOTA AMBON"},
    {"id":"8172","province_id":"81","label":"KOTA TUAL","value":"KOTA TUAL"},
    {"id":"8201","province_id":"82","label":"KAB. HALMAHERA BARAT","value":"KAB. HALMAHERA BARAT"},
    {"id":"8202","province_id":"82","label":"KAB. HALMAHERA TENGAH","value":"KAB. HALMAHERA TENGAH"},
    {"id":"8203","province_id":"82","label":"KAB. HALMAHERA UTARA","value":"KAB. HALMAHERA UTARA"},
    {"id":"8204","province_id":"82","label":"KAB. HALMAHERA SELATAN","value":"KAB. HALMAHERA SELATAN"},
    {"id":"8205","province_id":"82","label":"KAB. KEPULAUAN SULA","value":"KAB. KEPULAUAN SULA"},
    {"id":"8206","province_id":"82","label":"KAB. HALMAHERA TIMUR","value":"KAB. HALMAHERA TIMUR"},
    {"id":"8207","province_id":"82","label":"KAB. PULAU MOROTAI","value":"KAB. PULAU MOROTAI"},
    {"id":"8208","province_id":"82","label":"KAB. PULAU TALIABU","value":"KAB. PULAU TALIABU"},
    {"id":"8271","province_id":"82","label":"KOTA TERNATE","value":"KOTA TERNATE"},
    {"id":"8272","province_id":"82","label":"KOTA TIDORE KEPULAUAN","value":"KOTA TIDORE KEPULAUAN"},
    {"id":"9101","province_id":"91","label":"KAB. MERAUKE","value":"KAB. MERAUKE"},
    {"id":"9102","province_id":"91","label":"KAB. JAYAWIJAYA","value":"KAB. JAYAWIJAYA"},
    {"id":"9103","province_id":"91","label":"KAB. JAYAPURA","value":"KAB. JAYAPURA"},
    {"id":"9104","province_id":"91","label":"KAB. NABIRE","value":"KAB. NABIRE"},
    {"id":"9105","province_id":"91","label":"KAB. KEPULAUAN YAPEN","value":"KAB. KEPULAUAN YAPEN"},
    {"id":"9106","province_id":"91","label":"KAB. BIAK NUMFOR","value":"KAB. BIAK NUMFOR"},
    {"id":"9107","province_id":"91","label":"KAB. PUNCAK JAYA","value":"KAB. PUNCAK JAYA"},
    {"id":"9108","province_id":"91","label":"KAB. PANIAI","value":"KAB. PANIAI"},
    {"id":"9109","province_id":"91","label":"KAB. MIMIKA","value":"KAB. MIMIKA"},
    {"id":"9110","province_id":"91","label":"KAB. SARMI","value":"KAB. SARMI"},
    {"id":"9111","province_id":"91","label":"KAB. KEEROM","value":"KAB. KEEROM"},
    {"id":"9112","province_id":"91","label":"KAB PEGUNUNGAN BINTANG","value":"KAB PEGUNUNGAN BINTANG"},
    {"id":"9113","province_id":"91","label":"KAB. YAHUKIMO","value":"KAB. YAHUKIMO"},
    {"id":"9114","province_id":"91","label":"KAB. TOLIKARA","value":"KAB. TOLIKARA"},
    {"id":"9115","province_id":"91","label":"KAB. WAROPEN","value":"KAB. WAROPEN"},
    {"id":"9116","province_id":"91","label":"KAB. BOVEN DIGOEL","value":"KAB. BOVEN DIGOEL"},
    {"id":"9117","province_id":"91","label":"KAB. MAPPI","value":"KAB. MAPPI"},
    {"id":"9118","province_id":"91","label":"KAB. ASMAT","value":"KAB. ASMAT"},
    {"id":"9119","province_id":"91","label":"KAB. SUPIORI","value":"KAB. SUPIORI"},
    {"id":"9120","province_id":"91","label":"KAB. MAMBERAMO RAYA","value":"KAB. MAMBERAMO RAYA"},
    {"id":"9121","province_id":"91","label":"KAB. MAMBERAMO TENGAH","value":"KAB. MAMBERAMO TENGAH"},
    {"id":"9122","province_id":"91","label":"KAB. YALIMO","value":"KAB. YALIMO"},
    {"id":"9123","province_id":"91","label":"KAB. LANNY JAYA","value":"KAB. LANNY JAYA"},
    {"id":"9124","province_id":"91","label":"KAB. NDUGA","value":"KAB. NDUGA"},
    {"id":"9125","province_id":"91","label":"KAB. PUNCAK","value":"KAB. PUNCAK"},
    {"id":"9126","province_id":"91","label":"KAB. DOGIYAI","value":"KAB. DOGIYAI"},
    {"id":"9127","province_id":"91","label":"KAB. INTAN JAYA","value":"KAB. INTAN JAYA"},
    {"id":"9128","province_id":"91","label":"KAB. DEIYAI","value":"KAB. DEIYAI"},
    {"id":"9171","province_id":"91","label":"KOTA JAYAPURA","value":"KOTA JAYAPURA"},
    {"id":"9201","province_id":"92","label":"KAB. SORONG","value":"KAB. SORONG"},
    {"id":"9202","province_id":"92","label":"KAB. MANOKWARI","value":"KAB. MANOKWARI"},
    {"id":"9203","province_id":"92","label":"KAB. FAK FAK","value":"KAB. FAK FAK"},
    {"id":"9204","province_id":"92","label":"KAB. SORONG SELATAN","value":"KAB. SORONG SELATAN"},
    {"id":"9205","province_id":"92","label":"KAB. RAJA AMPAT","value":"KAB. RAJA AMPAT"},
    {"id":"9206","province_id":"92","label":"KAB. TELUK BINTUNI","value":"KAB. TELUK BINTUNI"},
    {"id":"9207","province_id":"92","label":"KAB. TELUK WONDAMA","value":"KAB. TELUK WONDAMA"},
    {"id":"9208","province_id":"92","label":"KAB. KAIMANA","value":"KAB. KAIMANA"},
    {"id":"9209","province_id":"92","label":"KAB. TAMBRAUW","value":"KAB. TAMBRAUW"},
    {"id":"9210","province_id":"92","label":"KAB. MAYBRAT","value":"KAB. MAYBRAT"},
    {"id":"9211","province_id":"92","label":"KAB. MANOKWARI SELATAN","value":"KAB. MANOKWARI SELATAN"},
    {"id":"9212","province_id":"92","label":"KAB. PEGUNUNGAN ARFAK","value":"KAB. PEGUNUNGAN ARFAK"},
    {"id":"9271","province_id":"92","label":"KOTA SORONG","value":"KOTA SORONG"}
  ];

const BANKS_VA = [ 
  { label: 'BRI Virtual Account', value: 'bri', image:bri}, 
  { label: 'BNI Virtual Account', value: 'bni', image:bni},
  { label: 'BCA Virtual Account', value: 'bca', image:bca},
  { label: 'Mandiri Bill Payment', value: 'mandiri', image:mandiri },
  { label: 'Permata Virtual Account', value: 'permata', image:permata}, 
];

export{
    ADMINISTRATOR_GROUP_ID,
    HOSPITAL_GROUP_ID,
    FINANCER_GROUP_ID,
    DOCTOR_GROUP_ID,

    PAYMENT_REQUEST_STATUS_PENDING,
    PAYMENT_REQUEST_STATUS_CANCELLED,
    PAYMENT_REQUEST_STATUS_REJECTED,
    PAYMENT_REQUEST_STATUS_APPROVED,
    PAYMENT_REQUEST_STATUSES,

    PAYMENT_REQUEST_TRANSFER_STATUS_NOT_PAYED,
    PAYMENT_REQUEST_TRANSFER_STATUS_PAYED,
    PAYMENT_REQUEST_TRANSFER_STATUSES,

    PAYMENT_EX_STATUSES,
    PAYOUT_EX_STATUSES,

    GENDERS,
    PATIENT_TYPES,
    PATIENT_PAYMENT_TYPES,
    PROVINCES,
    CITIES,

    BANKS_VA,
}