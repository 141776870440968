import { useLocation, Navigate, Outlet } from "react-router-dom";
import authService from "src/_services/auth.service";

const RequireAuth = ({ allowedRoles }) => {
    const location = useLocation();
    const user = authService.getCurrentUser();
    return (
        allowedRoles?.includes(user?.group)
            ? <Outlet />
            : user
                ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                : <Navigate to="/login" state={{ from: location }} replace />
    );
}

export default RequireAuth;